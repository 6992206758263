<template>
  <v-container >
    <div :style="$vuetify.breakpoint.smAndUp && user.role.name == 'student' ? 'position: fixed; text-align: center;' : ''">
      <v-btn 
        rounded 
        small 
        v-for="act in actions_by_role[user.role.name]" :key="act.text" 
        :color="act.color" 
        @click="act.action(course.id)"
        :outlined="act.is_outlined"
        :disabled='!act.available(course)'
        class="ma-1"
        :block="!$vuetify.breakpoint.smAndUp"
      ><v-icon left :color="act.icon_color">{{act.icon}}</v-icon>{{ act.text }}</v-btn>
    </div>

  </v-container>
</template>

<script>
import Api from '@/api/index'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'SelectedCourseCardActions',
  props: {
    is_component_use_in_student_home: {
      type: Boolean,
      default: false
    },
    selected_course: {
      type: Object,
      default: ()=> {}
    },
    short_description:{
      type: Boolean,
      default: false,
    }
  },
  components: {
    TotalEnrolled: () => import('@/components/student_counters/TotalEnrolled.vue'),
    TotalRegistered: () => import('@/components/student_counters/TotalRegistered.vue'),
    TotalPending: () => import('@/components/student_counters/TotalPending.vue'),
    CourseDetails: () => import('../CourseDetails.vue'),
  },
  data() {
    return {
      all_actions: {
        edit: {
          color: 'primary',
          icon_color: 'primary',
          available: (course) => !['done', 'suspended'].includes(course.status),
          is_outlined: true,
          action: ()=> this.editCourse(),
          icon: 'mdi-pencil',
          text: 'Editar curso'
        },
        select: {
          color: 'success',
          icon_color: 'success',
          available: (course) => ['active', 'selecting', 'done_selection'].includes(course.status),
          is_outlined: true,
          action: (course_id)=> this.$router.push({name: 'Select', params: {id: course_id}}),
          icon: 'mdi-account-check',
          text: 'Selecionar'
        },
        rate_student: {
          color: 'success',
          icon_color: 'success',
          available: (course) => ['in_progress'].includes(course.status),
          is_outlined: true,
          action: (course_id)=> this.$router.push({name: 'StudentRating', params: {id: course_id}}),
          icon: 'mdi-file-document-edit',
          text: 'Avaliar alunos'
        },
        suspend: {
          color: 'error',
          icon_color: 'error',
          available: (course) => ['active', 'selecting'].includes(course.status),
          is_outlined: true,
          action: ()=> this.suspendCourse(),
          icon: 'mdi-cancel',
          text: 'Suspender'
        },
        diretrizes: {
          color: 'primary',
          icon_color: 'white',
          available: (course) => course.info_file_url,
          is_outlined: false,
          action: ()=> this.openFile(),
          icon: 'mdi-file-download',
          text: 'diretrizes para inscrição'
        },
        enroll: {
          color: 'success',
          icon_color: 'white',
          available: (course) => ( !this.$dayjs(course.register_end_date).endOf('day').isBefore(this.$dayjs().startOf('day')) ) ,
          is_outlined: false,
          action: ()=> this.enrollInCourse(),
          icon: 'mdi-file-document-edit',
          text: 'Fazer inscrição'
        },
        rating: {
          color: 'primary',
          icon_color: 'primary',
          available: (course) => ['done'].includes(course.status),
          is_outlined: true,
          action: (course_id)=> this.$router.push({name: 'CourseRatings', params: {id: course_id}}),
          icon: 'mdi-comment-quote',
          text: 'Avaliações do curso'
        },
        certificates: {
          color: 'primary',
          icon_color: 'primary',
          available: (course) => ( ['done'].includes(course.status) && !course.is_international_course ),
          is_outlined: true,
          action: (course_id)=> this.$router.push({name: 'ConcludedCourseCertificates', params: {id: course_id}}),
          icon: 'mdi-certificate',
          text: 'Certificados'
        },
      },
      
      course: {},
      loading: false,
      enrolled: {
        value: 20000,
        title: 'Homologados',
        icon: 'mdi-account-check-outline',
        title_class: 'primary--text overline',
        icon_color: 'primary',
      },
      registered: {
        value: 20000,
        title: 'Inscritos',
        icon: 'mdi-account-plus-outline',
        title_class: 'primary--text overline',
        icon_color: 'primary',
      },
      pending: {
        value: 20000,
        title: 'Selecionados',
        icon: 'mdi-account-clock-outline',
        title_class: 'primary--text overline',
        icon_color: 'primary',
      },
    }
  },
  mounted() {
    this.setCourse()
  },
  methods: {
    openFile(){
      window.open(this.selected_course.info_file_url, '_blank');
    },
    setCourse(){
      this.loading = true

      if(this.selected_course){
        this.course = this.selected_course
        //  window.scrollTo(0, document.body.scrollHeight)
      }else if(this.$route.params.id){
        Api.Course.show(this.$route.params.id).then((r)=>{
          this.course = r.data
          //  window.scrollTo(0, document.body.scrollHeight)
        })
      }
      setTimeout(() => {
        this.loading = false
      }, 500);
    },
    editCourse(){
      this.$router.push({name: 'EditCourse',  params: {id: this.course.id}})
    },
    async enrollInCourse(){
      let canEnroll = await this.canEnrollCourse()
      if (canEnroll.is_valid_enroll){
        this.$router.push({name: 'CourseEnrollment', params: {course_id: this.course.id}}) 
      } else {
        this.addNotification({type: 'error', msg: canEnroll.error_msg})
      }
    },
    async canEnrollCourse(){
      return await Api.EnrollCourseValidate.index({course_id: this.course.id}).then(r => {
        return r.data                   
      }).catch(error => {
        console.log(error)
      })
    },
    suspendCourse(){
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        title: 'Deseja realizar operação?',
        icon: 'question',
      }).then((r) => { 
        if(r.isConfirmed){
          let course = this.prepareCourseStatus()
          this.updateCourse(course)
          this.$router.go()
        }
      })
    },
    updateCourse(course){
      Api.Course.update(course).then(()=>{
        this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
      }).catch(err => {
        this.addNotification({type: 'error', msg: `Erro: ${err}`})
      })
    },
    prepareCourseStatus(){
      let course = {
        id: this.course.id,
        status: 'suspended'
      }
      return course
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  computed: {
    actions_by_role(){
      let {edit, select, rate_student, suspend, diretrizes, enroll, rating, certificates} = this.all_actions
      return {
        super_admin: [
          edit,
          select,
          rate_student,
          rating,
          suspend,
          diretrizes,
          certificates,
        ],
        director: [
          edit,
          select,
          rate_student,
          rating,
          suspend,
          certificates,
        ],
        admin: [
          edit,
          select,
          rate_student,
          rating,
          suspend,
          certificates,
        ],
        coordinator: [
          select,
          rate_student,
          certificates
        ],
        tech_secretary: [
          certificates,
        ],
        student: [
          enroll,
          diretrizes,
        ]
      }
    },
    ...mapState({
      user: state=> state.User.user
    })
  },
  watch: {
    selected_course(){
      this.setCourse()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>